import { Verification } from '../types';

export const isApproved = (verifications?: Verification[]): boolean => {
  return !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.outcome === 'approved',
  );
};

export const isRejected = (verifications?: Verification[]): boolean => {
  return !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.outcome === 'rejected',
  );
};

export const validateMoneriumProofOfResidencyVerification = (
  verifications?: Verification[],
): boolean => {
  return !verifications?.find(
    (v) =>
      (v.kind === 'proofOfResidency' && v.outcome === 'approved') ||
      (v.kind === 'proofOfResidency' &&
        v.verifier === 'monerium' &&
        v.outcome !== 'rejected'),
  );
};

export const skipMoneriumProofOfResidencyVerification = (
  verifications?: Verification[],
): boolean => {
  const hasMoneriumVerification = !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.verifier === 'monerium',
  );

  const isMoneriumRejected = !!verifications?.find(
    (v) =>
      v.kind === 'proofOfResidency' &&
      v.verifier === 'monerium' &&
      v.outcome === 'rejected',
  );

  const isOnfidoApproved = !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.outcome === 'approved',
  );

  return isOnfidoApproved || (hasMoneriumVerification && !isMoneriumRejected);
};

export default {
  skipMoneriumProofOfResidencyVerification,
  validateMoneriumProofOfResidencyVerification,
  isApproved,
  isRejected,
};
