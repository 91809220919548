import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

/**
 * Use setInterval in functional React component with the same API.
 * Set your callback function as a first parameter and a delay (in milliseconds)
 * for the second argument. You can also stop the timer passing undefined instead the delay.
 * The main difference between the setInterval you know and this useInterval hook is
 * that its arguments are "dynamic". You can get more information in the Dan Abramov's
 * blog post https://overreacted.io/making-setinterval-declarative-with-react-hooks/.
 */
export function useInterval(callback: () => void, delay?: number) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(id);
    };
  }, [delay]);
}

/**
 * Very similar to the useInterval hook, this React hook implements the native setTimeout
 * function keeping the same interface. You can enable the timeout by setting delay
 * as a number or disabling it using undefined. When the time finishes,
 * the callback function is called.
 */
export function useTimeout(callback: () => void, delay?: number) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(id);
  }, [delay]);
}

export function useWindowScroll(
  callback: ({
    scrollY,
    scrollX,
    pageXOffset,
    pageYOffset,
  }: {
    scrollY: number;
    scrollX: number;
    pageXOffset: number;
    pageYOffset: number;
  }) => void,
) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the listener.
  useEffect(() => {
    function scroll() {
      const { scrollY, scrollX, pageYOffset, pageXOffset } = window;
      savedCallback.current({ scrollY, scrollX, pageYOffset, pageXOffset });
    }
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
}

export interface Size {
  width?: number;
  height?: number;
}

export function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
