import { Validation, ValidationErrors } from 'types/validationTypes';
import { parseBool } from './bool';

export const isTruthy = (value: unknown): boolean => parseBool(value);
export const isFalsy = (value: unknown): boolean => !isTruthy(value);
export const isYesOrNo = (value: unknown): boolean => {
  if (typeof value !== 'string') return false;
  const lower = value.toLowerCase();
  return lower === 'yes' || lower === 'no';
};

export class Validator {
  isValid = true;

  length = 0;

  errors: ValidationErrors = {};

  constructor(init?: Validation) {
    if (init) {
      this.isValid = init?.isValid;
      this.errors = init?.errors;
    }
  }

  response(): Validation {
    return {
      isValid: this.isValid,
      length: this.length,
      errors: this.errors,
    };
  }

  addError(key: string, message: string): Validation {
    this.isValid = false;
    this.length += 1;
    this.errors = {
      ...this.errors,
      [key]: message,
    };
    return this.response();
  }

  addErrors(errors: ValidationErrors): Validation {
    if (errors && Object.keys(errors).length > 0) {
      this.isValid = false;
      this.errors = {
        ...this.errors,
        ...errors,
      };
      this.length = Object.keys(this.errors).length;
    }
    return this.response();
  }
}

/**
 * Remove validation errors that are not relevant to the request e.g. in PATCH
 * @param validationErrors
 * @param record
 * @returns validation errors that share the same key as the record
 */
export const cleanValidationErrors = (
  validationErrors?: ValidationErrors,
  record?: Record<string, unknown>,
): ValidationErrors => {
  if (!validationErrors || !record) return {};
  if (typeof record === 'object' && record !== null) {
    return Object.keys(record).reduce((ve, i) => {
      if (validationErrors[i])
        return {
          ...ve,
          [i]: validationErrors[i],
        };
      return ve;
    }, {});
  }
  return {};
};

// Reset all validation errors that share the same key as a data set
export const resetValidationErrors = (
  validationErrors?: ValidationErrors,
  record?: Record<string, unknown>,
): ValidationErrors | undefined => {
  if (!validationErrors || !record) return undefined;
  return Object.keys(validationErrors).reduce((ve, i) => {
    if (!record[i])
      return {
        ...ve,
        [i]: validationErrors[i],
      };
    return ve;
  }, {});
};
