import React, { FC, HTMLAttributes } from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Stripe.less';

const Stripe: FC<
  {
    gradient?: 'teal' | 'pink' | 'tomato' | 'lime';
  } & HTMLAttributes<HTMLDivElement>
> = ({ gradient = 'teal', ...other }) => {
  useStyles(s);
  return <div className={cx(s.root, s[gradient])} {...other} />;
};

export default Stripe;
