import { validatePhoneNumberLength } from 'libphonenumber-js';
import { getDateNow, getYearsDiff } from 'utils/datetime';
import { isFalsy, isTruthy, isYesOrNo, Validator } from 'src/utils/validation';
import { validateMoneriumProofOfResidencyVerification } from '../Verification/ProofOfResidency/util';
import allowedCountries from './Form/Address/allowedCountries';
import allowedNationalities from './Form/General/allowedNationalities';
import { User } from './types';

export const validateUserGeneral = (
  user?: Partial<User>,
  valid = new Validator(),
): Validator => {
  if (!user?.firstName)
    valid.addError('firstName', 'Please enter your first name');
  if (!user?.lastName)
    valid.addError('lastName', 'Please enter your last name');
  if (!user?.birthday) valid.addError('birthday', 'Please enter your birthday');
  else if (getYearsDiff(user.birthday, getDateNow()) < 18) {
    valid.addError('birthday', 'Applicants must be over 18 years old');
  }
  if (!user?.nationality) {
    valid.addError('nationality', 'Please enter your nationality');
  } else if (
    !allowedNationalities.find(
      (x) => x.toLowerCase() === String(user.nationality).toLowerCase(),
    )
  )
    valid.addError(
      'nationality',
      "We're currently unable to accept customers of your nationality due to regulatory reasons",
    );
  if (!user?.phone) {
    valid.addError('phone', 'Please enter your phone number with country code');
  } else if (validatePhoneNumberLength(user?.phone)) {
    valid.addError('phone', 'Please enter a valid phone number');
  }
  return valid;
};

export const validateUserAddress = (
  user?: Partial<User>,
  valid = new Validator(),
): Validator => {
  if (!user?.address) valid.addError('address', 'Please enter your address');
  if (!user?.postalCode)
    valid.addError('postalCode', 'Please enter your postal code');
  if (!user?.city) valid.addError('city', 'Please enter your city');
  if (!user?.country) valid.addError('country', 'Please enter your country');
  else if (
    !allowedCountries.find(
      (x) => x.toLowerCase() === String(user.country).toLowerCase(),
    )
  )
    valid.addError(
      'country',
      'Only European countries are allowed at the moment',
    );

  return valid;
};

export const validateUserContact = (
  user?: Partial<User>,
  valid = new Validator(),
): Validator => {
  if (!user?.address) valid.addError('address', 'Please enter your address');
  if (!user?.postalCode)
    valid.addError('postalCode', 'Please enter your postal code');
  if (!user?.city) valid.addError('city', 'Please enter your city');
  if (!user?.country) valid.addError('country', 'Please enter your country');
  else if (
    !allowedCountries.find(
      (x) => x.toLowerCase() === String(user.country).toLowerCase(),
    )
  )
    valid.addError(
      'country',
      'Only European countries are allowed at the moment',
    );

  if (!user?.phone) {
    valid.addError('phone', 'Please enter your phone number with country code');
  } else if (validatePhoneNumberLength(user?.phone)) {
    valid.addError('phone', 'Please enter a valid phone number');
  }

  return valid;
};

export const validateProofOfResidency = (
  user?: Partial<User>,
  valid = new Validator(),
): Validator => {
  if (validateMoneriumProofOfResidencyVerification(user?.verifications))
    valid.addError(
      'proofOfResidenceDocument',
      'Please upload a proof of residence',
    );

  return valid;
};

export const validateUserTerms = (
  user?: Partial<User>,
  valid = new Validator(),
): Validator => {
  if (isTruthy(user?.usCitizen))
    valid.addError(
      'usCitizen',
      'Sorry, Monerium can not onboard US citizens at the moment.',
    );
  if (isTruthy(user?.usTaxPerson))
    valid.addError(
      'usTaxPerson',
      'Sorry, Monerium can not onboard US tax persons at the moment.',
    );
  if (!isYesOrNo(user?.usCitizen))
    valid.addError('usCitizen', 'Please select either yes or no');
  if (!isYesOrNo(user?.usTaxPerson))
    valid.addError('usTaxPerson', 'Please select either yes or no');
  if (isFalsy(user?.privacy))
    valid.addError('privacy', 'Please accept the terms');

  return valid;
};

export default (user?: Partial<User>, valid = new Validator()) => {
  if (isFalsy(user?.id)) valid.addError('id', 'User not found');
  validateUserContact(user, valid);
  validateUserTerms(user, valid);
  return valid;
};
