export const parseBool = (b: unknown): boolean => {
  if (typeof b === 'string') {
    const str = b.trim().toLowerCase();
    if (str === 'false') return false;
    if (str === 'no') return false;
    if (str === '') return false;
  }
  if (b && typeof b === 'object') {
    return Object.keys(b).length > 0;
  }
  return !!b;
};

export default {
  parseBool,
};
