import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from './VerificationCode.less';

const CODE_LENGTH = new Array(6).fill(0);

function isModifiedEvent(event: React.KeyboardEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  onChange: (value: string) => void;
  isFailure: boolean;
  defaultValue?: string;
}

const VerificationCode = ({
  id = 'verificationCode',
  onChange,
  isFailure,
  defaultValue = '',
  ...otherProps
}: Props) => {
  useStyles(s);
  const inputEl = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(defaultValue || '');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (isFailure) {
      setValue('');
    }
  }, [isFailure]);

  function handleClick() {
    inputEl?.current?.focus();
  }

  function handleWrapKeyUp(e: React.KeyboardEvent) {
    if (isModifiedEvent(e)) {
      return;
    }
    inputEl?.current?.focus();
  }

  function handleChange(v: string) {
    let newValue = value + v;
    if (newValue.length > CODE_LENGTH.length) {
      newValue = newValue.substring(0, CODE_LENGTH.length);
    }
    setValue(newValue);
    if (typeof onChange === 'function') onChange(newValue);
  }

  function handleKeyUp(e: React.KeyboardEvent) {
    if (e.key === 'Backspace') {
      const newValue = value.slice(0, value.length - 1);
      setValue(newValue);
      if (typeof onChange === 'function') onChange(newValue);
    }
  }

  function handleFocus() {
    setFocused(true);
  }

  function handleBlur() {
    setFocused(false);
  }

  const selectedIndex =
    value.length < CODE_LENGTH.length ? value.length : CODE_LENGTH.length - 1;
  const hideInput = value.length >= CODE_LENGTH.length;

  return (
    <div {...otherProps}>
      <div
        className={s.wrap}
        onClick={handleClick}
        onKeyUp={handleWrapKeyUp}
        role="textbox"
        tabIndex={0}
      >
        {CODE_LENGTH.map((v, index) => {
          const selected = index === selectedIndex;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${id}-${index}`} className={s.display}>
              {value.charAt(index)}
              {selected && focused && <div className={s.shadow} />}
            </div>
          );
        })}
        <input
          value=""
          ref={inputEl}
          className={cx(s.input, s[`offset${selectedIndex}`], {
            [s.hideInput]: hideInput,
          })}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e?.target?.value || '');
          }}
          onKeyUp={handleKeyUp}
        />
      </div>
    </div>
  );
};

export default VerificationCode;
