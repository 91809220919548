import { AppThunk } from 'src/customer/store/configureStore';
import { ValidationErrors } from 'src/types/validationTypes';
import ValidationError from 'src/utils/error';
import { AuthContext } from '../Login/types';
import { CONFIRM_FAILURE, CONFIRM_REQUEST, CONFIRM_SUCCESS } from './constants';
import service from './service';
import { ConfirmRequest } from './types';

const confirm = (param: ConfirmRequest): AppThunk<Promise<AuthContext>> => {
  const request = () => ({ type: CONFIRM_REQUEST });
  const success = (item: AuthContext) => ({ type: CONFIRM_SUCCESS, item });
  const failure = (error?: ValidationErrors) => ({
    type: CONFIRM_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const ctx = await service.confirm(param);
      dispatch(success(ctx));
      return ctx;
    } catch (err) {
      if (err instanceof ValidationError) dispatch(failure(err.errors));
      throw err;
    }
  };
};

export default {
  confirm,
};
