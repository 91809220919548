import rest from 'services/rest';
import { User } from './types';

const read = (): Promise<User> => rest.get('/api/kyc/users/me');
const store = (data: Partial<User>): Promise<User> =>
  rest.patch(`/api/kyc/users/me`, JSON.stringify(data)).then((response) => {
    if (response?.object) {
      // todo: refactor this response in the EMS backend.
      return {
        item: response.object,
        error: response?.errors?.errors || {},
      };
    }
    return response;
  });

/**
 * createVersion creates a user version
 */
const createVersion = (): Promise<User> =>
  rest.post(`/api/kyc/users/me/versions`);

export default {
  read,
  store,
  createVersion,
};
