import rest from 'services/rest';
import { Profile } from './types';

const list = (): Promise<Profile[]> => rest.get(`/api/iam/profiles`);

const read = (id: string): Promise<Profile> =>
  rest.get(`/api/kyc/profiles/${id}`);

const store = (id: string, data: Partial<Profile>): Promise<Profile> =>
  rest.patch(`/api/kyc/profiles/${id}`, data);

/**
 * createVersion creates a profile version
 * @param {string} id Profile ID
 */
const createVersion = (id: string): Promise<Profile> =>
  rest.post(`/api/kyc/profiles/${id}/versions`);

/**
 * Lists a versions for a profile
 * @param id Profile id
 * @returns list of all versions for this profile
 */
const versions = (id: string): Promise<Profile[]> =>
  rest.get(`/api/kyc/profiles/${id}/versions`);

export default {
  list,
  read,
  store,
  createVersion,
  versions,
};
