import rest from 'services/rest';
import { ConfirmRequest, ResendRequest } from './types';

const confirm = ({ token }: ConfirmRequest) =>
  rest.get(`/api/iam/confirm/${token}`);

const resend = (data: ResendRequest): Promise<{ success: boolean }> =>
  rest.patch(`/api/iam/confirm/email`, data);

export default {
  confirm,
  resend,
};
