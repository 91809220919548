export default [
  'AT', //  =>  ['name' => 'Austria', 'number' => '040', 'alpha3' => 'AUT'],
  'BE', //  =>  ['name' => 'Belgium', 'number' => '056', 'alpha3' => 'BEL'],
  'BG', //  =>  ['name' => 'Bulgaria', 'number' => '100', 'alpha3' => 'BGR'],
  'HR', //  =>  ['name' => 'Croatia', 'number' => '191', 'alpha3' => 'HRV'],
  'CY', //  =>  ['name' => 'Cyprus', 'number' => '196', 'alpha3' => 'CYP'],
  'CZ', //  =>  ['name' => 'Czechia', 'number' => '203', 'alpha3' => 'CZE'],//Czech Republic
  'DK', //  =>  ['name' => 'Denmark', 'number' => '208', 'alpha3' => 'DNK'],
  'EE', //  =>  ['name' => 'Estonia', 'number' => '233', 'alpha3' => 'EST'],
  'FI', //  =>  ['name' => 'Finland', 'number' => '246', 'alpha3' => 'FIN'],
  'FR', //  =>  ['name' => 'France', 'number' => '250', 'alpha3' => 'FRA'],
  'DE', //  =>  ['name' => 'Germany', 'number' => '276', 'alpha3' => 'DEU'],
  'GR', //  =>  ['name' => 'Greece', 'number' => '300', 'alpha3' => 'GRC'],
  'HU', //  =>  ['name' => 'Hungary', 'number' => '348', 'alpha3' => 'HUN'],
  'IE', //  =>  ['name' => 'Ireland', 'number' => '372', 'alpha3' => 'IRL'],
  'IT', //  =>  ['name' => 'Italy', 'number' => '380', 'alpha3' => 'ITA'],
  'LV', //  =>  ['name' => 'Latvia', 'number' => '428', 'alpha3' => 'LVA'],
  'LT', //  =>  ['name' => 'Lithuania', 'number' => '440', 'alpha3' => 'LTU'],
  'LU', //  =>  ['name' => 'Luxembourg', 'number' => '442', 'alpha3' => 'LUX'],
  'MT', //  =>  ['name' => 'Malta', 'number' => '470', 'alpha3' => 'MLT'],
  'NL', //  =>  ['name' => 'Netherlands', 'number' => '528', 'alpha3' => 'NLD'],
  'PL', //  =>  ['name' => 'Poland', 'number' => '616', 'alpha3' => 'POL'],
  'PT', //  =>  ['name' => 'Portugal', 'number' => '620', 'alpha3' => 'PRT'],
  'RO', //  =>  ['name' => 'Romania', 'number' => '642', 'alpha3' => 'ROU'],
  'SK', //  =>  ['name' => 'Slovakia', 'number' => '703', 'alpha3' => 'SVK'],
  'SI', //  =>  ['name' => 'Slovenia', 'number' => '705', 'alpha3' => 'SVN'],
  'ES', //  =>  ['name' => 'Spain', 'number' => '724', 'alpha3' => 'ESP'],
  'SE', //  =>  ['name' => 'Sweden', 'number' => '752', 'alpha3' => 'SWE'],
  'GB', //  =>  ['name' => 'United Kingdom', 'number' => '826', 'alpha3' => 'GBR'],
  'IS', //  =>  ['name' => 'Iceland', 'number' => '352', 'alpha3' => 'ISL'],
  'LI', //  =>  ['name' => 'Liechtenstein', 'number' => '438', 'alpha3' => 'LIE'],
  'NO', //  =>  ['name' => 'Norway', 'number' => '578', 'alpha3' => 'NOR'],
  'CH', //  =>  ['name' => 'Switzerland', 'number' => '756', 'alpha3' => 'CHE'],
];
