// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Az1Dp{display:block;position:relative;width:100%}.Az1Dp:after,.Az1Dp:before{background:var(--brand-color-blue);border-radius:2px;bottom:0;content:"";height:0;position:absolute;-webkit-transition:all .2s ease;transition:all .2s ease;width:0}.Az1Dp:before{left:50%}.Az1Dp:after{right:50%}.Jzn1k{background:var(--brand-color-red)}.is-focused~.Az1Dp:after,.is-focused~.Az1Dp:before,input:focus~.Az1Dp:after,input:focus~.Az1Dp:before{width:50%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": `Az1Dp`,
	"error": `Jzn1k`
};
module.exports = ___CSS_LOADER_EXPORT___;
