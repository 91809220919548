import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import cx from 'classnames';
import s from './FieldBar.less';

class FieldBar extends PureComponent {
  static propTypes = {
    error: PropTypes.bool,
  };

  static defaultProps = {
    error: false,
  };

  render() {
    const { error } = this.props;

    return (
      <span
        className={cx(s.bar, {
          [s.error]: error,
        })}
      />
    );
  }
}

export default withStyles(s)(FieldBar);
