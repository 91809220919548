import rest from 'services/rest';
import {
  INVITE_CODE_STORAGE_KEY,
  INVITE_CODE_UNLOCKED_STORAGE_KEY,
} from 'customer/constants';
import { InviteCode, InviteCodeResponse } from './types';

export const setInviteCode = (inviteCode: string) => {
  localStorage.setItem(INVITE_CODE_STORAGE_KEY, inviteCode);
};

export const getInviteCode = () => {
  return localStorage.getItem(INVITE_CODE_STORAGE_KEY);
};

export const removeInviteCode = () => {
  return localStorage.removeItem(INVITE_CODE_STORAGE_KEY);
};

export const getInviteCodeUnlocked = () => {
  return localStorage.getItem(INVITE_CODE_UNLOCKED_STORAGE_KEY);
};

// change to /iam/referral to use local mock data
export const list = (): Promise<InviteCode[]> =>
  rest.get(`/api/iam/referral`).then((i) => {
    return (i as InviteCodeResponse).referralCodes;
  });

// change to /iam/referral to use local mock data
export const requestInviteCodes = (): Promise<InviteCode[]> =>
  rest.post(`/api/iam/referral`).then((i) => {
    return (i as InviteCodeResponse).referralCodes;
  });

// Change to /iam/referral/${inviteCode}/redeem to test against mock server
export const registerInviteCode = (inviteCode: string): Promise<boolean> =>
  rest.post(`/api/iam/referral/${inviteCode}/redeem`);

// Change to /iam/referral/${inviteCode} to test against mock server
export const verifyInvite = (inviteCode: string): Promise<boolean> =>
  rest.get(`/api/iam/referral/${inviteCode}`);
