import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import {
  ReduxStore,
  useAppDispatch,
  useAppSelector,
} from 'customer/store/configureStore';
import actions from './actions';
import { CorporateProfile, PersonalProfile, Profile } from './types';

interface UseKycProfile {
  profile: PersonalProfile | CorporateProfile | undefined;
  read: (profileId: string) => Promise<Profile | void>;
  versions: (profileId: string) => Promise<Profile[] | void>;
}

const selectProfile = () =>
  useAppSelector((state: ReduxStore) => state.kyc.profile.detail, shallowEqual);

const useKycProfile = (): UseKycProfile => {
  const dispatch = useAppDispatch();

  return {
    profile: selectProfile(),
    read: useCallback(
      (profileId: string) =>
        dispatch(actions.read(profileId)).catch(console.error),
      [dispatch],
    ),
    versions: useCallback(
      (profileId: string) =>
        dispatch(actions.versions(profileId)).catch(console.error),
      [dispatch],
    ),
  };
};

export default useKycProfile;
