import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ws from 'isomorphic-style-loader-react18/withStyles';
import s from './EmailIcon.less';

function EmailIcon({ color }) {
  return (
    <div
      className={cx(s.root, {
        [s.success]: color === 'success',
        [s.danger]: color === 'danger',
      })}
    >
      <span
        style={{
          fontSize: '3rem',
          margin: '0',
        }}
        className={cx('material-icons', s.icon)}
      >
        mail_outline
      </span>
    </div>
  );
}

EmailIcon.propTypes = {
  color: PropTypes.oneOf(['default', 'success', 'danger']),
};
EmailIcon.defaultProps = {
  color: 'default',
};

export default ws(s)(EmailIcon);
